interface IServicePostProps {
    imageNumber: number;
    title: string;
    description: string;
}

function ServicePost({ imageNumber, title, description }: IServicePostProps) {
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="services-post">
                    <img src={`/media/services/${imageNumber}.jpg`} alt="" />
                </div>
            </div>
            <div className="col-md-6">
                <div className="services-post">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default ServicePost;
