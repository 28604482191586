import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProject } from "../../models/Project";
import PageBanner, { IBreadCrumb } from "../../components/PageBanner";
import { routes } from "../../constants";

interface IProjectDetailsViewProps {
    project: IProject;
}

function ProjectDetailsView({ project }: IProjectDetailsViewProps) {
    const ToProjectPath = (image: string) => {
        return `/media/projects/${project.id}/${image}`;
    };

    const breadcrumbs: IBreadCrumb[] = [
        {
            name: "Obras realizadas",
            link: routes.PROJECTS,
        },
        {
            name: project.address.line,
            link: `${routes.PROJECTS}/${project.id}`,
        },
    ];

    const categories: any = {
        construction: "Construção de cobretura",
    };

    const entityType: any = {
        private: "Privado",
        public: "Publico",
    };

    return (
        <>
            <PageBanner
                title={project.address.line}
                breadcrumbs={breadcrumbs}
            />
            <div className="single-page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <img
                                src={ToProjectPath(project.previewImage)}
                                alt=""
                            />
                        </div>
                        <div className="col-md-5">
                            <div className="project-content">
                                <div className="project-content-box">
                                    <h2>Detalhes</h2>
                                    <ul>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["far", "calendar-alt"]}
                                            />
                                            <span>Ano:</span>
                                            {project.date.getFullYear()}
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["fas", "map-marker-alt"]}
                                            />
                                            <span>Localização:</span>{" "}
                                            {project.address.line},{" "}
                                            {project.address.line2 &&
                                                project.address.line2 + ", "}
                                            {project.address.locality}
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["fas", "tag"]}
                                            />
                                            <span>Categoria:</span>{" "}
                                            {
                                                categories[
                                                    project.projectTypes[0]
                                                ]
                                            }
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["fas", "user"]}
                                            />
                                            <span>Entidade:</span>{" "}
                                            {entityType[project.entityType]}
                                        </li>
                                    </ul>
                                </div>
                                <div className="project-content-box">
                                    <h2>Descrição</h2>
                                    <p className="">{project.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="project-content">
                                <div className="project-content-box">
                                    <h2>Execução</h2>
                                    <ol>
                                        {project.execution.map((e, index) => (
                                            <li key={index}>{e}</li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <img
                                src={ToProjectPath(project.otherImages[0])}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        {project.otherImages.length > 1
                            ? project.otherImages.slice(1).map((i) => (
                                  <div className="col-md-6">
                                      <img src={ToProjectPath(i)} alt="" />
                                  </div>
                              ))
                            : null}
                    </div>
                    {project.video && (
                        <div className="row">
                            <div className="col-md-12">
                                <video width="100%" controls loop autoPlay>
                                    <source
                                        src={ToProjectPath(project.video)}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ProjectDetailsView;
