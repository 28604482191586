import ReferenceProject from "./ReferenceProject";
import { projects, routes } from "../../../constants";

function ReferenceProjectsSection() {
    return (
        <div className="presentation-section">
            <h3 className={"text-center mt-5"}>
                <strong>PROJETOS DE REFERÊNCIA</strong>
            </h3>

            {projects
                .filter((p) => p.isReference)
                .map((p, index) => {
                    return (
                        <ReferenceProject
                            key={p.id}
                            location={`${p.address.line}, ${p.address.locality}`}
                            description={p.description}
                            link={`${routes.PROJECTS}/${p.id}`}
                            orientation={index % 2 === 0 ? "left" : "right"}
                            image={`media/projects/${p.id}/${p.previewImage}`}
                        />
                    );
                })}
        </div>
    );
}

export default ReferenceProjectsSection;
