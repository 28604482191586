import { Container, Image, Nav, Navbar } from "react-bootstrap";
import clsx from "clsx";
import { checkIsActive } from "../../../utils/routeHelpers";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../../constants";

function NavigationBar() {
    const { pathname } = useLocation();

    const navigationLinks = [
        {
            name: "Inicio",
            link: routes.HOME,
        },
        {
            name: "Obras Realizadas",
            link: routes.PROJECTS,
        },
        {
            name: "Acerca de nós",
            link: routes.ABOUTUS,
        },
        {
            name: "Serviços",
            link: routes.SERVICES,
        },
        {
            name: "Contactos",
            link: routes.CONTACTUS,
        },
    ];

    return (
        <Container>
            <Navbar
                expand={"lg"}
                collapseOnSelect={true}
                className={"justify-content-between w-100"}
            >
                <Navbar.Brand as={Link} to={routes.HOME}>
                    <Image src={"/media/logo.png"} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={"navbarScroll"} />
                <Navbar.Collapse id={"navbarScroll"}>
                    <Nav className={"ms-auto"} navbarScroll={true}>
                        {navigationLinks.map((n, index) => (
                            <Nav.Link
                                key={index}
                                className={clsx({
                                    active: checkIsActive(pathname, n.link),
                                })}
                                to={n.link}
                                as={Link}
                            >
                                {n.name}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}

export default NavigationBar;
