import { IProject } from "../models/Project";

const projects: IProject[] = [
    {
        id: "palacete-estoril",
        date: new Date(2021, 1),
        address: {
            line: "Palacete D. Maria Pia",
            locality: "Estoril",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["5.jpg", "2.jpg", "4.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo da BMI COBERT, constituído por impermeabilização, isolamento térmico e revestimento cerâmico.",
        execution: [
            "Aplicação de Viroc",
            "Aplicação de Cobert Hyper 200 SK2 - Filme Impermeável Respirável",
            "Aplicação de Clima Pro T-397 100mm - Placas de Poliestireno Expandido (EPS)",
            "Aplicação de Ripa Metálica P/Cobert Insulation - Ripa Para Encaixar nas Placas de Isolamento",
            "Aplicação de Telha Lógica Marselha Vermelha Natural Hidrófuga",
            "Aplicação de Telha Lógica Marselha Branco Hidrófuga",
            "Peças Especiais",
        ],
        video: "video.mp4",
    },
    {
        id: "rua-fanqueiros",
        date: new Date(2021, 1),
        address: {
            line: "Rua dos Fanqueiros",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "3.jpg", "4.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema composto da Onduline e da Umbelino Monteiro, OSB 3 18mm, Ondutiss 130 da Onduline, isolamento com Roofmate, Sub-telha da Onduline, Ripada da Onduline, Revestimento cerâmico telha (Canudo) Da Umbelino Monteiro e sistema de impermeabilização em Rolo de Alumínio Flexível P/ Remates – ETEX da Umbelino Monteiro.",
        execution: [
            "Aplicação de OSB 3 de 18mm.",
            "Aplicação de Ondutiss Barrier Reflex 130 da Onduline.",
            "Aplicação de Isolamento Térmico de 40mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripa de metálica lacada da Onduline.",
            "Aplicação de telha cerâmica (Canudo) Da Umbelino Monteiro.",
            "Aplicação de Rolo de Alumínio Flexível P/ Remates - ETEX",
        ],
        video: "video.mp4",
    },
    {
        id: "lisboa-calcada",
        date: new Date(2021, 1),
        address: {
            line: "Calçada Das Necessidades",
            line2: "Nº. 8 e 10",
            locality: "Lisboa",
        },
        previewImage: "5.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["6.jpg", "2.jpg", "3.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo de estrutura metálica, painel OSB 4, Sub-telha da Onduline, Revestimento cerâmico da Torreense, sistema de impermeabilização Zincos.",
        execution: [
            "Execução de Estrutura Metálica.",
            "Aplicação de OSB 4 de 22mm.",
            "Aplicação de Ondutiss 150 Onduline.",
            "Aplicação de Isolamento Térmico de 100mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripa de metálica lacada da Onduline.",
            "Aplicação de telha Marselha cor Natural da Torreense.",
            "Aplicação de Zinco Nº. 12 Natural.",
            "Aplicação de Janelas Velux.",
        ],
        video: "video.mp4",
    },
    {
        id: "rua-imprensa-nacional",
        date: new Date(2021, 1),
        address: {
            line: "Rua Imprensa Nacional",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["4.jpg", "2.jpg", "3.jpg"],
        isReference: false,
        description:
            "Execução de cobertura com sistema completo da Onduline, Ondutiss 130 da Onduline, Impermeabilização com Roofmate, Sub-telha da Onduline, Ripada da Onduline, Revestimento cerâmico telha (Plasma) Da Coelho da Silva",
        execution: [
            "Aplicação de Ondutiss Barrier Reflex 130 da Onduline.",
            "Aplicação de Isolamento Térmico de 100mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripa de metálica lacada da Onduline.",
            "Aplicação de telha cerâmica (Plasma) Da Coelho da Silva.",
            "Aplicação de Janelas Velux.",
            "Impermeabilização com Zinco.",
        ],
        video: "video.mp4",
    },
    {
        id: "lisboa-travessa",
        date: new Date(2021, 1),
        address: {
            line: "Travessa das Almas",
            locality: "Lisboa",
        },
        previewImage: "2.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["1.jpg", "3.jpg", "4.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo de estrutura Madeira, painel OSB 3, Ondutiss 150 da Onduline, Sub-telha da Onduline, Revestimento cerâmico da Torreense, sistema de impermeabilização Danosa.",
        execution: [
            "Execução de Estrutura madeiras Lameladas e de Pinho aparelhado.",
            "Aplicação de OSB 3 de 15mm.",
            "Aplicação de Ondutiss 150 Onduline.",
            "Aplicação de Isolamento Térmico de 40mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripa de metálica lacada da Onduline.",
            "Aplicação de telha Marselha cor Natural da Torreense.",
            "Aplicação de Janelas Velux.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
            "Aplicação de lã de Rocha de 40mm na laje de esteira.",
        ],
    },
    {
        id: "hotel-rural-belas",
        date: new Date(2020, 1),
        address: {
            line: "Hotel Rural Belas",
            locality: "Lisboa",
        },
        previewImage: "5.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["3.jpg", "2.jpg", "1.jpg"],
        isReference: true,
        description:
            "Construção de cobertura e telha cerâmica (Plasma) Da Coelho da Silva, Perfil Omega da Coelho da Silva, Perfil em Z da Coelho da Silva, Isolamento Térmico da DOW, sistema de impermeabilização da Danosa.",
        execution: [
            "Regularização da base com argamassa.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Aplicação de Perfil em Z de 90mm.",
            "Aplicação de Isolamento Térmico de 60mm.",
            "Aplicação de Perfil em Omega.",
            "Aplicação de telha Plasma.",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
        ],
        video: "video.mp4",
    },
    {
        id: "estoril",
        date: new Date(2019, 1),
        address: {
            line: "Estoril",
            locality: "Lisboa",
        },
        previewImage: "3.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "1.jpg", "4.jpg"],
        isReference: true,
        description:
            "Cobertura realizada com telha marselha (Advance Premium) da UMBELINO MONTEIRO, isolamento térmico da DOW, sistema de impermeabilização da ONDULINE.",
        execution: [
            "Regularização da base com argamassas cimentícias.",
            "Aplicação de Ondutiss Air 150 da Onduline.",
            "Aplicação de Roofmate de 100mm.",
            "Aplicação de subtelha ST 50 da Onduline.",
            "Aplicação de Onduband e Ondufilm da Onduline",
            "Aplicação ripa metálica da Onduline.",
            "Aplicação de telha advance premium marseille preta.",
            "Execução de fecho de comeeiras com argamassas.",
        ],
    },
    {
        id: "cabral-amadora",
        date: new Date(2018, 1),
        address: {
            line: "Avenida Curry Cabral",
            locality: "Amadora",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["4.jpg", "2.jpg", "3.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo de estrutura de madeiras mistas, painel Sandwiche da Mundiperfil e sistema de impermeabilização da Danosa.",
        execution: [
            "Execução de Estrutura Madeira Lameladas (Vigas)",
            "Execução de estrutura Madeira de pinho (Varas)",
            "Aplicação de apinel PAINEL POLIURETANO COBERTURA 5 ONDAS - Chapa de açoAplicação de apinel PAINEL POLIURETANO COBERTURA 5 ONDAS - Chapa de aço galvanizada na qualidade S250GD+Z, segundo EN 10346:2009 + EN 10169, revestida com poliéster regular modificado, e tolerâncias de espessura segundo EN 10143.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
        ],
        video: "video.mp4",
    },
    {
        id: "lisboa-restelo",
        date: new Date(2012, 1),
        address: {
            line: "Restelo",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["3.jpg", "7.jpg", "8.jpg"],
        isReference: false,
        description:
            "Execução de cobertura com sistema completo de estrutura de madeiras Lameladas, painel Sandwiche da Onduline, Sub-telha da Dupont, Revestimento cerâmico da Coelho da Silva e sistema de impermeabilização da Danosa.",
        execution: [
            "Execução de Estrutura Madeira Lameladas – Vigas – Varas – Apoios Metálicos.",
            "Aplicação de Painel Sanduiche H19+A100+FAB 13 – Aglomerado Hidrófugo de 19mm + Isolamento Térmico 100mm + Friso Abeto Envernizado de 13mm da ONDULINE.",
            "Aplicação de contra ripa de madeira de pinho tratada.",
            "Aplicação de subtelha DUPONT – Tyvek.",
            "Aplicação de ripa de madeira de pinho tratada.",
            "Aplicação de telha lusa da Coelho da Silva.",
            "Aplicação de beirado a portuguesa da Coelho da Silva.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
            "Aplicação de Janelas Velux.",
        ],
    },
    {
        id: "lisboa-principe-real",
        date: new Date(2010, 1),
        address: {
            line: "Principe Real",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "8.jpg", "9.jpg"],
        isReference: false,
        description:
            "Execução de cobertura com sistema completo de estrutura metálica, painel Sandwiche feito em obra, Sub-telha da Onduline, Revestimento cerâmico da Umbelino Monteiro, sistema de impermeabilização da Danosa e Zincos.",
        execution: [
            "Execução de Estrutura Metálica.",
            "Aplicação de OSB3 de 22mm.",
            "Aplicação de Isolamento Térmico de 60mm.",
            "Aplicação de OSB3 de 18mm.",
            "Aplicação de subtelha ST 50 da Onduline.",
            "Aplicação de ripa de PVC da Onduline.",
            "Aplicação de telha Marselha perta da Umbelino Monteiro.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela.",
            "Aplicação de Zinco Nº. 12 Natural.",
            "Aplicação de Janelas Velux.",
        ],
    },
    {
        id: "sao-pedro",
        date: new Date(2014, 1),
        address: {
            line: "São Pedro",
            locality: "Lisboa",
        },
        previewImage: "3.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["4.jpg", "1.jpg", "2.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo de impermeabilização da Danosa.",
        execution: [
            "Remoção dos Impermeabilizantes existentes.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
        ],
    },
    {
        id: "sao-marcos",
        date: new Date(2010, 1),
        address: {
            line: "São Marcos",
            locality: "Lisboa",
        },
        previewImage: "3.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "1.jpg", "4.jpg"],
        isReference: false,
        description:
            "Reabilitação das Caleiras da cobertura com sistema completo de impermeabilização da Danosa.",
        execution: [
            "Remoção do Impermeabilizante Existente.",
            "Execução de betonilha com pendentes de 3%.",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster.",
            "Reposição da telha cerâmica.",
        ],
    },
    {
        id: "rua-duques-braganca",
        date: new Date(2022, 1),
        address: {
            line: "Rua dos Duques de Braganca",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "3.jpg", "4.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema composto da Onduline e da Coelho da Silva, OSB 3 22mm, Ondutiss 130 da Onduline, isolamento Térmico Placas de Lã de Rocha 100mm, Sub-telha da Onduline, Ripado de Pinho com tratamento Autoclave. Revestimento cerâmico telha (Canudo Envelhecida) Da Coelho da Silva e sistema de impermeabilização em Zinco.",
        execution: [
            "Aplicação de OSB 3 de 22mm.",
            "Aplicação de Ondutiss Barrier Reflex 130 da Onduline.",
            "Aplicação de Isolamento Térmico Placas de Lã de Rocha 100mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripado de Pinho com tratamento Autoclave.",
            "Aplicação de telha cerâmica (Canudo Envelhecida) Da Coelho da Silva.",
        ],
        video: "video.mp4",
    },
    {
        id: "rua-vitor",
        date: new Date(2022, 1),
        address: {
            line: "Rua Vitor Cordon",
            locality: "Lisboa",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "3.jpg", "4.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema composto da Onduline e da Coelho da Silva, OSB 3 18mm, Ondutiss 130 da Onduline, isolamento com Roofmate, Sub-telha da Onduline, Revestimento cerâmico telha (Canudo) Da Coelho da Silva e sistema de impermeabilização em Zinco.",
        execution: [
            "Aplicação de OSB 3 de 18mm.",
            "Aplicação de Ondutiss Barrier Reflex 130 da Onduline.",
            "Aplicação de Isolamento Térmico de 80mm.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de telha cerâmica (Canudo) Da Coelho da Silva.",
        ],
    },
    {
        id: "camara-pestana",
        date: new Date(2022, 2),
        address: {
            line: "Rua Câmara Pestana, Nº2 e 4",
            locality: "Sintra",
        },
        previewImage: "1.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema composto da Onduline, OSB 3 18mm, Painel da ACH, Ondutiss 130 da Onduline, Sub-telha da Onduline, Ripada da Onduline, Beirados a Portuguesa, Revestimento cerâmico telha (Santo Antonio) e sistema de impermeabilização em Rolo de Alumínio Flexível P/ Remates da Onduline.",
        execution: [
            "Aplicação de OSB 3 de 18mm.",
            "Aplicação de painel sandwich com faces em chapas de aço e núcleo em lã de rocha de alta densidade, com 80mm de espessura, “ACH”",
            "Aplicação de Ondutiss Barrier Reflex 130 da Onduline.",
            "Aplicação de subtelha ST 150 da Onduline.",
            "Aplicação de ripa de metálica lacada da Onduline.",
            "Aplicação de beirado a portuguesa duplo.",
            "Aplicação de telha cerâmica Santo António.",
            "Aplicação de Rolo de Alumínio Flexível P/ Remates - ETEX",
        ],
    },
    {
        id: "oscar-monteiro",
        date: new Date(2024, 1),
        address: {
            line: "Avenida Óscar Monteiro, Nº. 19",
            locality: "Lisboa",
        },
        previewImage: "2.jpg",
        entityType: "private",
        projectTypes: ["construction"],
        otherImages: ["1.jpg", "3.jpg"],
        isReference: true,
        description:
            "Execução de cobertura com sistema completo de Reparação e tratamento da estrutura Madeira,  painel OSB 3, Ondutiss 150 da Onduline, Sub-telha da Onduline, Revestimento cerâmico da Torreense,  sistema de impermeabilização Danosa.",
        execution: [
            "Reparação e tratamento da estrutura madeira existente",
            "Aplicação de OSB 3 de 12mm",
            "Aplicação de Ondutiss Barrier Reflex da Onduline",
            "Aplicação de Isolamento Térmico de 50mm",
            "Aplicação de subtelha ST 150 da Onduline",
            "Aplicação de ripa de metálica lacada da Onduline",
            "Aplicação de telha Lusa cor Natural da Torreense",
            "Aplicação de Janela Velux",
            "Aplicação de duas demãos de emulsão betuminosa, (CURIDAN).",
            "Impermeabilização com emulsão betuminosa, (CURIDAN), Primário, (GLASDAN 30 P PLAST) 1ª tela, (IMPERDAN FP 40 GP) 2ª Tela acabamento Xisto, todas Poliéster",
        ],
        video: "video.mp4",
    },
];

export default projects.sort((p1, p2) => p2.date.getTime() - p1.date.getTime());
