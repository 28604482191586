import PageBanner, { IBreadCrumb } from "../../components/PageBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { info, routes } from "../../constants";
import { Link, Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import { checkIsActive } from "../../utils/routeHelpers";

function ServicesLayout() {
    const { pathname } = useLocation();
    const breadcrumbs: IBreadCrumb[] = [
        {
            name: "Serviços",
            link: routes.SERVICES,
        },
    ];

    const services = [
        {
            title: "Construção e Reparação de Telhados/Coberturas",
            link: "construction",
        },
        {
            title: "Limpeza e Manutenção de Telhados",
            link: "maintenance",
        },
        {
            title: "Isolamentos Térmicos e Impermeabilizações",
            link: "isolation",
        },
        {
            title: "Estruturas Metálicas, Madeira, Pré-fabricadas e Betão Armado",
            link: "structures",
        },
        {
            title: "Remoção de Telhados com chapas com amianto",
            link: "removal",
        },
    ];

    const ToServicesLink = (path: string) => `${routes.SERVICES}/${path}`;

    return (
        <div>
            <PageBanner title={"Serviços"} breadcrumbs={breadcrumbs} />
            <div className="services-page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="side-navigation">
                                <ul className="side-navigation-list">
                                    <li>
                                        <div>
                                            <Link
                                                className={clsx({
                                                    active:
                                                        pathname ===
                                                        "/services",
                                                })}
                                                to={routes.SERVICES}
                                            >
                                                Serviços
                                                <FontAwesomeIcon
                                                    className={"float-end"}
                                                    icon={[
                                                        "fas",
                                                        "angle-right",
                                                    ]}
                                                />
                                            </Link>
                                        </div>
                                    </li>
                                    {services.map((s) => (
                                        <li key={s.title} className={"active"}>
                                            <Link
                                                className={clsx({
                                                    active: checkIsActive(
                                                        pathname,
                                                        ToServicesLink(s.link)
                                                    ),
                                                })}
                                                to={ToServicesLink(s.link)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={[
                                                        "fas",
                                                        "chevron-circle-right",
                                                    ]}
                                                />
                                                &nbsp;{s.title}
                                                <FontAwesomeIcon
                                                    className={"float-end"}
                                                    icon={[
                                                        "fas",
                                                        "angle-right",
                                                    ]}
                                                />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <div className="contact-info">
                                    <h2>Informação de Contato</h2>
                                    <ul className="information-list">
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["fas", "map-marker-alt"]}
                                            />
                                            <span>{info.address.line}</span>
                                            <span>
                                                {info.address.postalCode}{" "}
                                                {info.address.locality}
                                            </span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["fas", "phone"]}
                                            />
                                            <span>{info.telephone}</span>
                                            {info.mobiles.map((m) => (
                                                <span key={m}>{m}</span>
                                            ))}
                                        </li>
                                        <li>
                                            <FontAwesomeIcon
                                                icon={["far", "envelope"]}
                                            />
                                            <a href={`mailto:${info.mail}`}>{info.mail}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="services-wrapp">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesLayout;
