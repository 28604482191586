import ContactTopBar from "../layouts/MasterLayout/components/ContactTopBar";
import NavigationBar from "../layouts/MasterLayout/components/NavigationBar";

function Header() {
    return (
        <header>
            <nav
                className="navbar navbar-default navbar-fixed-top m-0 p-0"
                role="navigation"
            >
                <ContactTopBar />
                <NavigationBar />
            </nav>
        </header>
    );
}

export default Header;
