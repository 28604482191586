import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { info } from "../../../constants";

function ContactTopBar() {
    return (
        <div className="top-line w-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <p>
                            <span>
                                <FontAwesomeIcon icon={["fas", "phone"]} />
                                {info.telephone}
                            </span>
                            <span>
                                <FontAwesomeIcon icon={["fas", "mobile-alt"]} />
                                {info.mobiles[0]}
                            </span>
                            <span>
                                <FontAwesomeIcon icon={["fas", "mobile-alt"]} />
                                {info.mobiles[1]}
                            </span>
                            <span>
                                <FontAwesomeIcon icon={["far", "envelope"]} />
                                {info.mail}
                            </span>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <ul className="social-icons">
                            <li>
                                <a
                                    className="facebook"
                                    href="https://www.facebook.com/zonaplus.telhados/"
                                    target="new"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "facebook-f"]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="twitter"
                                    href="https://twitter.com/carloszonaplus"
                                    target="new"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "twitter"]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="google"
                                    href="https://plus.google.com/u/0/112599655466750453540/posts"
                                    target="new"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "google-plus-g"]}
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="linkedin"
                                    href="http://pt.linkedin.com/pub/carlos-catarino/43/a19/7a2"
                                    target="new"
                                >
                                    <FontAwesomeIcon
                                        icon={["fab", "linkedin-in"]}
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactTopBar;
