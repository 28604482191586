import PageBanner, { IBreadCrumb } from "../../components/PageBanner";
import { projects, routes } from "../../constants";
import ProjectPost from "./components/ProjectPost";
import { useState } from "react";
import ProjectListFilter from "./components/ProjectListFilter";
import { IProject } from "../../models/Project";

import "./projectshome.scss";

function ProjectsHomeView() {
    const breadcrumbs: IBreadCrumb[] = [
        {
            name: "Obras realizadas",
            link: routes.PROJECTS,
        },
    ];

    const years = Array.from(
        new Set(projects.map((p) => p.date.getFullYear()))
    ).sort((x, y) => y - x);

    const filters = [
        {
            id: 0,
            name: "Mostrar todos",
            predicate: (p: IProject) => true,
        },
        ...years.map((y, index) => {
            return {
                id: index + 1,
                name: y.toString(),
                predicate: (p: IProject) => p.date.getFullYear() === y,
            };
        }),
    ];

    const [visibleProjects, setVisibleProjects] = useState(projects);
    const [activeFilter, setActiveFilter] = useState(filters.at(0)?.id ?? 1);

    return (
        <div>
            <PageBanner title={"Obras realizadas"} breadcrumbs={breadcrumbs} />
            <div className="projects-page-section">
                <div className="container">
                    <ul className="filter">
                        {filters.map((f) => (
                            <ProjectListFilter
                                key={f.id}
                                name={f.name}
                                isActive={f.id === activeFilter}
                                onClick={() => {
                                    setVisibleProjects(
                                        projects.filter(f.predicate)
                                    );
                                    setActiveFilter(f.id);
                                }}
                            />
                        ))}
                    </ul>
                    <div className="project-row row">
                        {visibleProjects.map((p, i) => (
                            <ProjectPost
                                key={i}
                                name={p.id}
                                title={`${p.address.line}`}
                                previewImage={p.previewImage}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectsHomeView;
