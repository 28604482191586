import HomeCarousel from "./components/HomeCarousel";
import "./homeview.scss";
import ReferenceProjectsSection from "./components/ReferenceProjectsSection";
import { Link } from "react-router-dom";
import { routes } from "../../constants";

function Home() {
    return (
        <div>
            <HomeCarousel />

            <div className="banner-section">
                <div className="container">
                    <h2>
                        Tudo o que precisa para proteger o seu telhado está aqui
                        <Link to={routes.CONTACTUS} className="button-one">
                            Pedir Orçamento
                        </Link>
                    </h2>
                </div>
            </div>

            <ReferenceProjectsSection />
        </div>
    );
}

export default Home;
