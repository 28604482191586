import clsx from "clsx";

interface IProjectListFilterProps {
    name: string;
    isActive: boolean;
    onClick: () => void;
}

function ProjectListFilter({
    name,
    isActive,
    onClick,
}: IProjectListFilterProps) {
    return (
        <li>
            <div
                className={clsx({
                    active: isActive,
                })}
                onClick={onClick}
            >
                {name}
            </div>
        </li>
    );
}

export default ProjectListFilter;
