import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export interface IContactFormDto {
    name: string;
    email: string;
    description?: string | undefined;
}

export class ContactClient {
    private axiosInstance: AxiosInstance;
    private baseUrl: string;

    constructor(instance?: AxiosInstance, baseUrl?: string) {
        this.axiosInstance = instance ?? axios.create();
        this.baseUrl = baseUrl ?? "";
    }

    sendForm(formValues: IContactFormDto): Promise<any> {
        const formData = new FormData();

        formData.append("name", formValues.name);
        formData.append("email", formValues.email);
        formData.append("description", formValues.description ?? "");

        const options: AxiosRequestConfig = {
            data: formData,
            method: "POST",
            url: this.baseUrl + "/contact.php",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "text/plain",
            },
        };

        return this.axiosInstance
            .request(options)
            .then((response: AxiosResponse) => {
                return Promise.resolve<string>("Successful");
            })
            .catch((error: any) => {
                console.error(error);
                throw error;
            });
    }
}
