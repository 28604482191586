import PageBanner, { IBreadCrumb } from "../../components/PageBanner";
import { info, routes } from "../../constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./contactus.scss";
import FormInput from "./components/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { contactClient } from "../../clients";

function ContactUsView() {
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Nome e um campo obrigatorio")
            .max(256, "Nome não pode ter mais do que 256 caracteres"),
        email: Yup.string()
            .email("Email tem que ter um formato valido")
            .required("Email e um campo obrigatorio"),
        description: Yup.string(),
    });

    const intialValues = {
        name: "",
        email: "",
        description: "",
    };

    const formik = useFormik({
        initialValues: intialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const response = await contactClient.sendForm(values);
            console.log(response);
            setSubmitting(false);
        },
    });

    const breadcrumbs: IBreadCrumb[] = [
        {
            name: "Contatos",
            link: routes.CONTACTUS,
        },
    ];

    return (
        <div>
            <PageBanner title={"Contato"} breadcrumbs={breadcrumbs} />
            <div className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact-info">
                                <h2>Informação de Contato</h2>
                                <p>
                                    Pode entrar em contato connosco ou fazer-nos
                                    uma visita durante a semana de Segunda a
                                    Sexta das 9:00 ás 18:00
                                </p>
                                <ul className="information-list">
                                    <li>
                                        <FontAwesomeIcon
                                            icon={["fas", "map-marker-alt"]}
                                        />
                                        <span>{info.address.line}</span>
                                        <span>{`${info.address.postalCode} ${info.address.locality}`}</span>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={["fas", "phone"]}
                                        />
                                        {info.mobiles
                                            .concat(info.telephone)
                                            .map((n, i) => (
                                                <span key={i}>{n}</span>
                                            ))}
                                    </li>
                                    <li>
                                        <FontAwesomeIcon
                                            icon={["far", "envelope"]}
                                        />
                                        <a href={`mailto:${info.mail}`}>
                                            {info.mail}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <form
                                noValidate={true}
                                onSubmit={formik.handleSubmit}
                                id="contact-form"
                            >
                                <h2>Envie-nos uma Mensagem</h2>
                                <p>
                                    Entraremos em contato consigo tão breve
                                    quanto possivel.
                                </p>
                                <div className="row">
                                    <div className="col">
                                        <FormInput
                                            formik={formik}
                                            field={"name"}
                                        />
                                    </div>
                                    <div className="col">
                                        <FormInput
                                            formik={formik}
                                            field={"email"}
                                        />
                                    </div>
                                </div>
                                <textarea
                                    {...formik.getFieldProps("description")}
                                    className={"mt-3"}
                                    placeholder="Mensagem ou Pedido de Orçamento"
                                ></textarea>
                                <input
                                    type="submit"
                                    value={"Enviar Mensagem"}
                                    disabled={
                                        formik.isSubmitting ||
                                        !formik.isValid ||
                                        !formik.touched
                                    }
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsView;
