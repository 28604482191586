import { Image } from "react-bootstrap";

interface IReferenceProjectProps {
    location: string;
    description: string;
    link: string;
    orientation: "left" | "right";
    image: string;
}

interface IReferenceProjectImageProps {
    image: string;
}

function ReferenceProjectImage({ image }: IReferenceProjectImageProps) {
    return (
        <div className={"col-md-8"}>
            <Image src={image} />
        </div>
    );
}

interface IReferenceProjectDescriptionProps {
    location: string;
    description: string;
    link: string;
}

function ReferenceProjectDescription({
    location,
    description,
    link,
}: IReferenceProjectDescriptionProps) {
    return (
        <div className={"col-md-4"}>
            <h2>{location}</h2>
            <p>{description}</p>
            <a href={link}>Mais...</a>
        </div>
    );
}

function ReferenceProject({
    location,
    description,
    link,
    orientation,
    image,
}: IReferenceProjectProps) {
    return (
        <div className={"presentation-line"}>
            <div className={"container"}>
                <div className={"row"}>
                    {orientation === "left" ? (
                        <ReferenceProjectImage image={image} />
                    ) : (
                        <ReferenceProjectDescription
                            location={location}
                            description={description}
                            link={link}
                        />
                    )}

                    {orientation === "left" ? (
                        <ReferenceProjectDescription
                            location={location}
                            description={description}
                            link={link}
                        />
                    ) : (
                        <ReferenceProjectImage image={image} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ReferenceProject;
