const info = {
    telephone: "(+351) 211 368 931",
    mobiles: ["(+351) 91 969 32 29", "(+351) 91 357 43 27"],
    mail: "geral@zonaplus.pt",
    address: {
        line: "Rua Combatentes de 9 de Abril - Corpo B2",
        postalCode: "2695-699",
        locality: "São João Talha",
    },
};

export default info;
