import { ToAbsoluteUrl } from "../../utils/assetHelpers";
import { Link } from "react-router-dom";

function ServicesView() {
    return (
        <div className="">
            <div className="row">
                <div className="col-md-6">
                    <div className="services-post">
                        <img
                            src={ToAbsoluteUrl("media/services/1.jpg")}
                            alt=""
                        />
                        <h2>Construção e Reparação de Telhados/Coberturas</h2>
                        <Link to={"construction"}>Mais informação...</Link>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="services-post">
                        <img
                            src={ToAbsoluteUrl("media/services/2.jpg")}
                            alt=""
                        />
                        <h2>Limpeza e Manutenção de Telhados</h2>
                        <Link to={"maintenance"}>Mais informação...</Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="services-post">
                        <img
                            src={ToAbsoluteUrl("media/services/3.jpg")}
                            alt=""
                        />
                        <h2>Isolamentos Térmicos e Impermeabilizações</h2>
                        <Link to={"isolation"}>Mais informação...</Link>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="services-post">
                        <img
                            src={ToAbsoluteUrl("media/services/4.jpg")}
                            alt=""
                        />
                        <h2>
                            Estruturas Metálicas, Madeira, Pré-fabricadas e
                            Betão Armado
                        </h2>
                        <Link to={"structures"}>Mais informação...</Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="services-post">
                        <img
                            src={ToAbsoluteUrl("media/services/5.jpg")}
                            alt=""
                        />
                        <h2>Remoção de Telhados com chapas com amianto</h2>
                        <Link to={"removal"}>Mais informação...</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesView;
