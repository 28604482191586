import PageBanner from "../../components/PageBanner";
import { ToAbsoluteUrl } from "../../utils/assetHelpers";
import { routes } from "../../constants";

function AboutUs() {
    return (
        <div>
            <PageBanner
                title={"Acerca de nos"}
                breadcrumbs={[
                    {
                        name: "Acerca de nos",
                        link: routes.ABOUTUS,
                    },
                ]}
            />

            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img
                                src={ToAbsoluteUrl(
                                    "media/vehicles/vehicle1.jpg"
                                )}
                                alt=""
                            />
                            <h2>Quem somos</h2>
                            <p>
                                A Zonaplus Lda dedica-se à Construção, Reparação
                                e Manutenção de Telhados e conta com uma equipa
                                de profissionais especializada e com experiência
                                comprovada, capaz de responder às necessidades
                                dos nossos clientes, sempre com
                                profissionalismo, dedicação, qualidade e
                                rapidez.
                            </p>
                            <p>
                                No mercado há mais de 15 anos, aperfeiçoamos
                                diariamente as nossas técnicas de trabalho,
                                adaptando-nos a um mercado cada vez mais
                                exigente na relação preço/qualidade. Adquirimos
                                novas formas de trabalho para apresentar aos
                                nossos clientes, quer sejam particulares ou
                                imponentes nomes de Empresas Nacionais.
                            </p>
                            <p>
                                Temos experiência comprovada em Reparações,
                                Manutenções, Impermeabilizações e Isolamentos.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img
                                src={ToAbsoluteUrl(
                                    "media/vehicles/vehicle2.jpg"
                                )}
                                alt=""
                            />
                            <h2>O que fazemos</h2>
                            <p>
                                Actualmente, os problemas que encontramos com
                                maior frequência nas coberturas, são as
                                infiltrações e as telhas quebradas. Estes
                                fenómenos devem-se, muitas vezes, à forma como a
                                cobertura é construída, que por não ser sempre a
                                mais correcta, origina problemas como estes,
                                desnecessários e evitáveis, mas que lhe causam
                                vários prejuízos e gastos.
                            </p>
                            <p>
                                O telhado é um dos elementos mais importantes da
                                sua habitação, se não estiver em bom estado,
                                compromete tudo o resto.
                            </p>
                            <p>
                                O nosso objectivo é evitar este problema,
                                apostando na qualidade dos nossos materiais e na
                                eficácia do nosso trabalho, proporcionando-lhe
                                conforto térmico e protegendo a sua casa de
                                goteiras indesejadas, utilizando materiais
                                impermeáveis, evitando, ainda, a criação de
                                fungos e a propagação de pragas.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="team-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className={"text-uppercase"}>
                                <strong>Conheça as nossas instalações</strong>
                            </h3>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="team-post">
                                <img
                                    src={ToAbsoluteUrl("media/office/1.jpg")}
                                    alt="Escritório"
                                />
                                <h2>Escritório</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="team-post">
                                <img
                                    src={ToAbsoluteUrl("media/office/2.jpg")}
                                    alt="Sala de Reuniões"
                                />
                                <h2>Sala de Reuniões</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="team-post">
                                <img
                                    src={ToAbsoluteUrl("media/office/3.jpg")}
                                    alt="Edifício"
                                />
                                <h2>Edifício</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="team-post">
                                <img
                                    src={ToAbsoluteUrl("media/office/4.jpg")}
                                    alt="Armazém"
                                />
                                <h2>Armazém</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
