import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function MasterLayout() {
    return (
        <Container fluid={true} className={"p-0"}>
            <Header />

            <Outlet />

            <Footer />
        </Container>
    );
}

export default MasterLayout;
