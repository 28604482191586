export function getCurrentUrl(pathname: string) {
    return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
    const current = getCurrentUrl(pathname);

    if (!current || !url) {
        return false;
    }

    if (current === url) {
        return true;
    }

    if (url !== "/" && current.indexOf(url) > -1) {
        console.log(url, pathname);
        return true;
    }

    return false;
}
