import { routes } from "../constants";
import { Link } from "react-router-dom";

export interface IBreadCrumb {
    name: string;
    link: string;
}

interface IPageBannerProps {
    title: string;
    breadcrumbs?: IBreadCrumb[];
}

function PageBanner({ title, breadcrumbs }: IPageBannerProps) {
    return (
        <div className={"page-banner-section mt-0"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <h2>{title}</h2>
                    </div>
                    <div className={"col-md-6"}>
                        <ul className={"page-depth"}>
                            <li>
                                <Link to={routes.HOME}>Zonaplus</Link>
                            </li>
                            {breadcrumbs &&
                                breadcrumbs.map((b) => (
                                    <li key={b.name}>
                                        <Link to={b.link}>{b.name}</Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageBanner;
