import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../constants";
import MasterLayout from "../layouts/MasterLayout/MasterLayout";
import HomeView from "../views/home/HomeView";
import AboutUs from "../views/aboutus/AboutUs";
import ServicesRoutes from "./ServicesRoutes";
import ProjectRoutes from "./ProjectRoutes";
import ContactUsView from "../views/contactus/ContactUsView";

function PublicRoutes() {
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route index path={routes.HOME} element={<HomeView />} />
                <Route path={routes.ABOUTUS} element={<AboutUs />} />
                <Route
                    path={`${routes.SERVICES}/*`}
                    element={<ServicesRoutes />}
                />
                <Route
                    path={`${routes.PROJECTS}/*`}
                    element={<ProjectRoutes />}
                />
                <Route path={routes.CONTACTUS} element={<ContactUsView />} />
                <Route path={"*"} element={<Navigate to={routes.HOME} />} />
            </Route>
        </Routes>
    );
}

export default PublicRoutes;
