import { Link } from "react-router-dom";

interface IProjectPostProps {
    name: string;
    title: string;
    previewImage: string;
}

function ProjectPost({ title, name, previewImage }: IProjectPostProps) {
    return (
        <div className="col-xl-4 col-lg-6 col-sm-12 project-container mt-2 mb-2">
            <Link to={name}>
                <img src={`media/projects/${name}/${previewImage}`} alt="" />
                <div className="project-overlay">
                    <h2>{title}</h2>
                </div>
            </Link>
        </div>
    );
}

export default ProjectPost;
