import { Navigate, Route, Routes } from "react-router-dom";
import { projects, routes } from "../constants";
import ProjectsHomeView from "../views/projects/ProjectsHomeView";
import ProjectDetailsView from "../views/projectdetails/ProjectDetailsView";

function ProjectRoutes() {
    return (
        <Routes>
            <Route index path={routes.HOME} element={<ProjectsHomeView />} />

            {projects.map((p) => (
                <Route
                    key={p.id}
                    path={p.id}
                    element={<ProjectDetailsView project={p} />}
                />
            ))}

            <Route path={"*"} element={<Navigate to={routes.PROJECTS} />} />
        </Routes>
    );
}

export default ProjectRoutes;
