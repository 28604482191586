interface ICarouselVideoProps {
    video: string;
}

function CarouselVideo({ video }: ICarouselVideoProps) {
    return (
        <video
            className={"d-block w-100 home-carousel-item"}
            loop={true}
            autoPlay={true}
        >
            <source src={video} type="video/mp4" />
        </video>
    );
}

export default CarouselVideo;
