import { info } from "../constants";

function Footer() {
    return (
        <footer>
            <div className="up-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-widget">
                                <h2>Acerca de nós</h2>
                                <p>
                                    A Zonaplus dedica-se à Construção e
                                    Reabilitação de Coberturas. Temos
                                    experiência comprovada em Reparações,
                                    Manutenções, Impermeabilizações e
                                    Isolamentos.
                                </p>
                                <img src="images/footer-logo.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-widget info-widget">
                                <h2>Informação</h2>
                                <p className="first-par">
                                    Contacte-nos ou visite-nos durante o horário
                                    de trabalho.
                                </p>
                                <p>
                                    <span>Telefone: </span> {info.telephone}
                                </p>
                                <p>
                                    <span>Telemovel: </span> {info.mobiles[0]}
                                </p>
                                <p>
                                    <span>Telemovel: </span> {info.mobiles[1]}
                                </p>
                                <p>
                                    <span>Email: </span> {info.mail}
                                </p>
                                <p>
                                    <span>Horário: </span> 9:00 - 13:00 / 14:00
                                    - 18:00
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="copyright">
                &copy; Copyright 2015. Baseado no template "Housebuild". Criado
                por <a href="www.micro-r.com">micro-r.com</a>. Todos os direitos
                reservados.
            </p>
        </footer>
    );
}

export default Footer;
