import { Carousel } from "react-bootstrap";
import featured_videos from "../../../constants/featured_videos";
import CarouselVideo from "./CarouselVideo";

function HomeCarousel() {
    return (
        <Carousel>
            {featured_videos.map((v, i) => (
                <Carousel.Item className="overlay">
                    <CarouselVideo key={i} video={v} />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default HomeCarousel;
