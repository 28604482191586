interface IFormInputProps {
    formik: any;
    field: string;
    className?: string;
}

function FormInput({ formik, field, className }: IFormInputProps) {
    return (
        <>
            <input
                {...formik.getFieldProps(field)}
                type="text"
                placeholder={field[0].toUpperCase() + field.slice(1)}
                className={className}
            />

            {formik.touched[field] && formik.errors[field] && (
                <div>
                    <span className={"alert text-danger"}>
                        {formik.errors[field]}
                    </span>
                </div>
            )}
        </>
    );
}

export default FormInput;
