import { Navigate, Route, Routes } from "react-router-dom";
import ServicesLayout from "../layouts/ServicesLayout/ServicesLayout";
import ServicesView from "../views/services/ServicesView";
import { routes } from "../constants";
import ServicePost from "../views/services/components/ServicePost";

function ServicesRoutes() {
    const servicePosts = [
        {
            path: "construction",
            title: "Construção e Reparação de Telhados/Coberturas",
            description:
                "A empresa ZonaPlus tem uma basta experiência na área da construção e reparação de telhados e é com base nesta experiência adquirida e consolidada, que desenvolvemos as melhores soluções para os problemas dos nossos clientes. Utilizamos produtos de elevada qualidade e eficiência, de forma a tornar a habitação menos vulnerável a fatores externos, isto é, variações de temperatura, ruídos e humidade, dando-lhes assim o devido conforto.",
        },
        {
            path: "maintenance",
            title: "Limpeza e Manutenção de Telhados",
            description:
                "Somos especializados em serviços de limpeza de telhados e na sua manutenção. Possuímos uma equipa de intervenções capacitada para responder com eficácia a qualquer situação e utilizamos os mais avançados sistemas de limpeza, o que nos habilita a responder com sucesso a qualquer solicitação.",
        },
        {
            path: "isolation",
            title: "Isolamentos Térmicos e Impermeabilizações",
            description:
                "A impermeabilização e isolamento de telhados nunca deverá ser descurada. Deverá ser efetuada o mais rapidamente possível sempre que haja alguma infiltração, pois uma má impermeabilização causará danos muito superiores num curto espaço de tempo. O isolamento deve ser aplicado, pois tem vários benefícios para a habitação impedindo a entrada de frio e restitui o calor emitido, reduzindo o consumo energético no Verão (ar condicionado) e no Inverno (aquecimento central), evitando as perdas de energia.",
        },
        {
            path: "structures",
            title: "Estruturas Metálicas, Madeira, Pré-fabricadas e Betão Armado",
            description:
                "Quanto à estrutura do telhado, ou seja, o sistema que vai dar sustentação e inclinação às telhas, utilizamos na sua construção diversas técnicas de construção e materiais, como estrutura em madeira, metálicas, pré-fabricadas ou betão",
        },
        {
            path: "removal",
            title: "Remoção de Telhados com chapas com amianto",
            description:
                "A aplicação de amianto em novos edifícios não é permitida por lei por provocar doenças cancerígenas a pessoas que estejam expostas ao mesmo durante um longo período de tempo. A nossa equipa é especializada e certificada na sua remoção e substituição.",
        },
    ];

    return (
        <Routes>
            <Route element={<ServicesLayout />}>
                <Route index path={routes.HOME} element={<ServicesView />} />
                {servicePosts.map((value, index) => (
                    <Route
                        key={index}
                        path={value.path}
                        element={
                            <ServicePost
                                imageNumber={index + 1}
                                title={value.title}
                                description={value.description}
                            />
                        }
                    />
                ))}
                <Route path={"*"} element={<Navigate to={routes.SERVICES} />} />
            </Route>
        </Routes>
    );
}

export default ServicesRoutes;
